import React from 'react';
import './arrow.scss';

function Arrow() {
  return (
    <div className="arrow-container">
      <div className="scroll-arrow"></div>
      <div className="scroll-arrow"></div>
      <div className="scroll-arrow"></div>
    </div>
  )
}

export default Arrow;
